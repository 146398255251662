import React, {  useContext, useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import Contact from "../components/sections/Contact";
import Herosection from "../components/sections/Herosection";
import Services from "../components/sections/Services";
import Works from "../components/sections/Works";
import LocaleContext from "../LocaleContext";

function Homepage() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-2")) {
      setToggleMenu(false);
    }
  });
  const { locale } = useContext(LocaleContext);

  const handleClasses = () => {


    let classes = "content";
    if (toggleMenu & locale === "en") {
      classes = "content open";
    } else if (!toggleMenu & locale === "en") {
      classes = "content";
    } else if (toggleMenu & locale === "ar") {
      classes = "content-2 open";
    } else if (!toggleMenu & locale === "ar") {
      classes = "content-2";
    }
    return classes;
  }
  return (
      <>
        <Header
          logoSource="/images/logo.svg"
          toggleMenu={toggleMenu}
          headerToggler={headerToggler}
        />
        <main className={handleClasses()}>
          <Element name="section-home">
            <ReactCursorPosition>
              <Herosection />
            </ReactCursorPosition>
          </Element>
          <Element name="section-about">
            <About />
          </Element>
          <Element name="section-services">
            <Services />
          </Element>
          <Element name="section-works">
            <Works />
          </Element>

          <Element name="section-blogs">
            <Blogs />
          </Element>
          <Element name="section-contact">
            <Contact />
          </Element>
          <div className="spacer" data-height="96"></div>
        </main>
      </>

  );
}

export default Homepage;
