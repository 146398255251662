import React from "react";
import Pagetitle from "../elements/Pagetitle";
import { saveAs } from 'file-saver'

const downloadImage = () => {
  saveAs('/images/BLUE-02.png') // Put your image url here.
}

const aboutContent = {
  name: "Bolby",
  avatarImage: "/images/avatar1.png",
  content: "Design studio and a space to share everything about the visual design world and provide its services.",
  subcontent: "📍Based in Tripoli, Libya.",
};

  


function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About The Studio" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div> 
          </div>
          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                  <p>{aboutContent.content}</p>
                  <p>{aboutContent.subcontent}</p>
                  <div className="mt-3">
                    <button onClick={downloadImage} className="btn btn-defaultz">
                      Download
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;
